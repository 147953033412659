import { createApp } from 'vue'
import App from './App.vue'
import mitt from 'mitt';
import router from './router'

// Vue.prototype.$eventHub = new Vue();

// Vue.config.productionTip = false;

// new Vue({
//   router,
//   render: h => h(App)
// }).$mount("#app");
const emitter = mitt();
const app = createApp(App);

app.config.globalProperties.emitter = emitter;
app.use(router).mount('#app');