
import { Vue } from "vue-class-component";
import { Success } from "@lib/success";
import { Events } from "@utils/Events";

export default class Notification extends Vue {
    State = false;
    type: string;
    message: string;

    created() {
        this.emitter.on(Events.ShowNotification, (notification: Success) => {
            this.NotificationState(notification);
        });
    }

    public NotificationState(notification: Success) {
        this.State = true;
        this.message = notification.Message;
        if (notification.Status >= 200 && notification.Status < 300) {
            this.type = "success"
        } else {
            this.type = "error";
        }
        setTimeout(() => {
            this.State = false;
        }, 3000);
    }
}
