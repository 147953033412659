
import { Vue } from "vue-class-component";
import { Events } from "@utils/Events";

export default class Loading extends Vue {
    State = false;

    created() {
        this.emitter.on(Events.Loading, (flag: boolean) => {
            this.State = flag;
        });
    }
}
